import { useState } from "react";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Auth } from "@hotel-engine/services";
import {
  Box,
  Button,
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetScrollView,
  SheetTitle,
  SheetTrigger,
  toast,
  Typography,
} from "@hotelengine/atlas-web";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../../../../Settings/helpers";
import ChangePasswordForm from "../ChangePasswordForm";
import * as Styled from "./styles";

import { routes } from "@hotel-engine/constants";
import type { IChangePasswordFormValues } from "../../types";

const ChangePasswordModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const initialValues: IChangePasswordFormValues = {
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };
  const handleLockOut = async () => {
    await Auth.signOut("Settings.handleLockOut");
    navigate(routes.loggedOut);
  };
  const _handleSubmit = (values, { setErrors, setSubmitting }) => {
    const currentPassword = values.currentPassword;
    const newPassword = values.newPassword;
    const newPasswordConfirmation = values.passwordConfirmation;

    if (newPassword !== newPasswordConfirmation) {
      setErrors({ general: "Passwords do not match" });
      setSubmitting(false);
    } else {
      setSubmitting(true);

      Auth.resetPassword(currentPassword, newPassword, true)
        .then(async () => {
          toast({
            title: "Your password has been updated",
            sentiment: "positive",
            icon: "circle-check",
          });
          await Auth.signOut("Settings.components.MyProfile.ChangePassword");
          setIsOpen(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.log("error: ", error);
          if (error.locked) {
            return handleLockOut();
          }
          const defaultError = [
            "There was a problem saving your information. Please contact support if the problem persists.",
          ];
          const submitError = error?.data?.errors[0] || defaultError;
          setErrors({ general: submitError });
          setSubmitting(false);
          return;
        });
    }
  };

  return (
    <Sheet isOpen={isOpen}>
      <SheetTrigger>
        <Styled.ChangePasswordButton
          variant="outlined"
          color="secondary"
          size="md"
          onClick={() => setIsOpen(true)}
        >
          <Typography data-testid="account-security-change-password-button" variant="body/md">
            Change password
          </Typography>
        </Styled.ChangePasswordButton>
      </SheetTrigger>
      <Formik
        onSubmit={_handleSubmit}
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
      >
        {({ values, isSubmitting, setErrors, setSubmitting, errors }) => (
          <SheetContent>
            <SheetHeader>
              <Styled.HeaderContainer>
                <SheetTitle style={{ marginBottom: "32px" }}>
                  <Typography as="h2" variant="heading/lg">
                    Change your password
                  </Typography>
                </SheetTitle>
                <SheetClose
                  onClick={() => {
                    setIsOpen(false);
                    setErrors({ currentPassword: "", newPassword: "", passwordConfirmation: "" });
                  }}
                />
              </Styled.HeaderContainer>
            </SheetHeader>
            <SheetScrollView>
              <Styled.FormContainer>
                <ChangePasswordForm />
              </Styled.FormContainer>
              <Box
                as="footer"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                marginTop={16}
                marginBottom={16}
              >
                <Box display="flex">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setIsOpen(false);
                      setErrors({ currentPassword: "", newPassword: "", passwordConfirmation: "" });
                    }}
                  >
                    Cancel
                  </Button>
                  <Box marginLeft={16}>
                    <Button
                      onClick={() => _handleSubmit(values, { setErrors, setSubmitting })}
                      isLoading={isSubmitting}
                      isDisabled={
                        !!errors.currentPassword ||
                        !!errors.newPassword ||
                        !!errors.passwordConfirmation
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </SheetScrollView>
          </SheetContent>
        )}
      </Formik>
    </Sheet>
  );
};

export default ChangePasswordModal;
