import { Box, Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const FormContainer = styled(Box).attrs({
  display: "flex",
  flexDirection: "column",
})`
gap: ${({ theme }) => theme.spacing[12]};
margin-bottom: ${({ theme }) => theme.spacing[32]};
`;

export const HeaderContainer = styled(Box).attrs({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
})`
width: 100%;
`;

export const ChangePasswordButton = styled(Button)`
  height: 40px;
  text-wrap: nowrap;
`;
